import { http } from '../utils/http';

const publicPath = '/api/driver-center/driver/mine/carrierAudit';

export const apiSearchVerifyList = async params => {
  return await http.post(`${publicPath}/search`, params, false);
};

export const apiAddVerifyInfo = async params => {
  return await http.post(`${publicPath}/add`, params, false);
};

export const apiUpdateVerifyInfo = async params => {
  return await http.post(`${publicPath}/update`, params, false);
};

export const apiGetVerifyInfo = async params => {
  return await http.post(`${publicPath}/get`, params, false);
};
