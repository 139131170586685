const idCardPattern = /(^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)/;

export const driverAuthStepFormOne = {
  // 表单数据流
  driverName: {
    label: '司机姓名',
    autosize: false,
    type: 'text',
    rules: [
      { required: true, message: '司机姓名不能为空' },
      { pattern: /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{1,20}$/, message: '非合法名称' }
    ]
  },
  driverIdCard: {
    label: '司机身份证号',
    type: 'text',
    autosize: false,
    rules: [
      { required: true, message: '司机身份证号不能为空' },
      {
        pattern: idCardPattern,
        message: '身份证信息不合法'
      }
    ]
  }
};

const carLicencePattern = /^[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新]{1}[A-Z]{1}[A-Z0-9]{5,6}$/;

export const carAuthStepFormOne = {
  // 表单数据流
  carLicensePlate: {
    label: '车牌号',
    autosize: false,
    type: 'text',
    rules: [
      { required: true, message: '车牌号不能为空' },
      {
        pattern: carLicencePattern,
        message: '车牌号格式错误'
      }
    ]
  }
};

export const tagMap = {
  1: { title: '审核中', color: 'warning' },
  2: { title: '审核通过', color: 'primary' },
  3: { title: '审核驳回', color: 'danger' },
  4: { title: '未提交', color: 'warning' }
};

export const usageMap = {
  1: '为司机直系亲属',
  2: '挂靠车车老板收款',
  3: '非挂靠车车老板收款'
};
