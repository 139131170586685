import { defineStore } from 'pinia';
import { apiGetVerifyInfo } from '@/api/carrier-audit';

export const useIdVerifiedStore = defineStore({
  id: 'id-verify',
  state: () => ({
    verifiedInfo: {
      step: 0,
      auditStatus: 0,
      id: null,

      usage: 1, //场景
      // 核验对象是司机
      driverName: '',
      driverIdCard: '',
      driverIdCardFrontFilename: '',
      driverIdCardBackFilename: '',
      // 核验对象是车辆
      drivingLicenseFilename: '',
      carLicensePlate: '',
      // 证明材料
      attachmentFileUrls: '',

      refuseReason: ''
    }
  }),
  getters: {
    getAuthStatus() {
      return this.verifiedInfo.auditStatus;
    },
    getIsEdit() {
      return !!this.verifiedInfo.id;
    }
  },
  actions: {
    initInfo() {
      this.verifiedInfo = {
        step: 0,
        auditStatus: 0,
        id: null,

        usage: 1, //场景
        // 核验对象是司机
        driverName: '',
        driverIdCard: '',
        driverIdCardFrontFilename: '',
        driverIdCardBackFilename: '',
        // 核验对象是车辆
        drivingLicenseFilename: '',
        carLicensePlate: '',
        // 证明材料
        attachmentFileUrls: '',

        refuseReason: ''
      };
    },
    updateInfo(val) {
      this.verifiedInfo = Object.assign(this.verifiedInfo, val);
    },

    async queryIdVerifiedInfo(id) {
      const res = await apiGetVerifyInfo({ id });
      const { data } = res;
      if (data) {
        this.verifiedInfo = { ...data, step: 0 };
      } else {
        this.verifiedInfo.step = 0;
      }
      return data;
    }
  }
});
