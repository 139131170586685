<template>
  <Page :hasBg="false" class="verify-content">
    <!-- 导航栏 -->
    <NavBar title="身份核验" @clickLeft="handleRouterBack" :customClickLeft="true" :leftArrow="true"> </NavBar>
    <div class="h-full w-full relative">
      <div v-if="dataList.length" class="px-8 overflow-y-auto" style="height: calc(100% - 80px)">
        <div
          v-for="item in dataList"
          :key="item.id"
          class="p-16 bg-white mt-8 relative"
          style="border: 1px solid #e2e2e2; border-radius: 0.04rem;"
          @click="handleDetail(item.id)"
        >
          <div v-if="item.usage === 1" class="font-500">
            <div class="text-16">{{ item.driverName }} {{ item.driverIdCard }}</div>
            <div class="mt-16">验证场景：{{ usageMap[item.usage] }}</div>
            <div class="mt-8">创建时间：{{ dayjs(item.createTime).format('YYYY-MM-DD HH:mm') }}</div>
          </div>
          <div v-else class="font-500">
            <div class="text-16">{{ item.carLicensePlate }}</div>
            <div class="mt-16">验证场景：{{ usageMap[item.usage] }}</div>
            <div class="mt-8">创建时间：{{ dayjs(item.createTime).format('YYYY-MM-DD HH:mm') }}</div>
          </div>
          <div
            class="text-white px-16 py-8 absolute text-14 leading-14"
            :class="`bg-${tagMap[item.auditStatus].color}`"
            style="top: 0; right: 0; border-bottom-left-radius: 0.04rem; border-top-right-radius: 0.04rem;"
          >
            {{ tagMap[item.auditStatus].title }}
          </div>
        </div>
      </div>
      <div v-else class="w-full h-full flex items-center justify-center text-16 font-500">暂无核验记录</div>
      <div class="absolute bottom-0 w-full flex justify-center px-32" style="height: 48px;bottom: 20px">
        <Button @click="handleVerify" plain type="primary" class="w-full" style="height: 48px">发起核验</Button>
      </div>
    </div>
  </Page>
</template>

<script>
import NavBar from '@/components/NavBar/index.vue';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { Button } from 'vant';
import { PageEnum } from '@/enums/pageEnum';
import { useRouter } from '@/utils/compsitionHack';
import { apiSearchVerifyList } from '@/api/carrier-audit';
import dayjs from 'dayjs';
import { useIdVerifiedStore } from '@/store/idVerify';
import { tagMap, usageMap } from './constants';
export default defineComponent({
  name: 'VerifyList',
  components: {
    NavBar,
    Button
  },
  setup(_, ctx) {
    const idVertifiedStore = useIdVerifiedStore();
    const router = useRouter();
    const dataList = ref([]);

    // 改变页面背景颜色
    onMounted(async () => {
      const res = await apiSearchVerifyList({
        pageNum: 1,
        pageSize: 50
      });

      if (res?.data) {
        dataList.value = res?.data.records;
      }
    });

    const handleVerify = () => {
      idVertifiedStore.initInfo();
      router.push({ path: PageEnum.ID_VERIFY_PAGE });
    };

    const handleRouterBack = () => {
      ctx.root.$routerBack.call(ctx.root._router);
    };

    const handleDetail = id => {
      idVertifiedStore.initInfo();
      idVertifiedStore.queryIdVerifiedInfo(id);
      router.push({ path: PageEnum.ID_VERIFY_DETAIL });
    };

    return {
      dayjs,
      tagMap,
      dataList,
      usageMap,
      handleDetail,
      handleVerify,
      handleRouterBack
    };
  }
});
</script>
<style lang="less"></style>
